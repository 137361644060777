<template>
  <q-dialog
    v-model="createPopUp.$state.MobilcreatePopUp"
    :persistent="createPopUp.$state.MobilPopUpPersistent"
    :position="createPopUp.$state.Mobilposition"
    ref="mobilPopUpRef"
  >
    <div
      class="mobil-popup"
      :style="{ top: positionTop + 'px' }"
      :class="{ 'return-to-top': positionTop === 0 }"
      v-touch-pan.mouse.down="handleSwipe"
    >
      <span class="mobil-popup-pull"></span>
      <component :is="createPopUp.$state.MobilcurrentPopUp"></component>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'

const createPopUp = PopUpStore()

const positionTop = ref<number>(0)
const mobilPopUpRef = ref()

const handleSwipe = ({ evt, ...newInfo }) => {
  positionTop.value = newInfo.distance.y
  if (newInfo.isFinal) {
    if (positionTop.value >= 90) {
      mobilPopUpRef.value.hide()
      positionTop.value = 0
    } else {
      positionTop.value = 0
    }
  }
}
</script>

<style lang="scss">
.mobil-popup {
  height: 100%;
  max-height: 100% !important;
  width: 100vw !important;
  max-width: 100vw !important;
  overflow: hidden;
  border-radius: 12px 12px 0px 0px !important;
  background: var(--White, #fff);
  padding: 16px;
  display: grid;
  justify-items: center;
  position: relative;
  &.return-to-top {
    transition: top 0.2s ease; // Adjust duration and easing as needed
  }

  &-pull {
    display: flex;
    width: 48px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Slate-300, #dbdae5);
  }
  .page__head-boxTitle {
    justify-self: flex-start;
    margin-bottom: 35px;
  }
  .butts-conteiner {
    display: grid;
    margin-top: 26px;
    gap: 8px;
    button {
      display: flex;
      width: 328px;
      padding: 13px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
    }
    .end-deal {
      background: var(--Slate-100, #f5f5fc);
      border: none;
    }
    .cancel {
      border: 1px solid var(--Slate-200, #e9e9f3);
      background: var(--White, #fff);
    }
  }
}
.q-dialog__inner--minimized{
  padding: 0;
}
</style>
